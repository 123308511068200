import React from "react";
import { Helmet } from "react-helmet";
import shape1 from "../../assets/img/shape/shape1.png";
import shape2 from "../../assets/img/shape/shape2.png";
import shape3 from "../../assets/img/shape/shape3.png";
import shape4 from "../../assets/img/shape/shape4.png";
import shape5 from "../../assets/img/shape/shape5.png";
import shape6 from "../../assets/img/shape/shape6.png";
import sliderImage from "../../assets/img/slider/slider-image.png";
import icon from "../../assets/img/icon/icon.png";
import icon2 from "../../assets/img/icon/icon2.png";
import icon3 from "../../assets/img/icon/icon3.png";
import shapeS1 from "../../assets/img/shape/shape-s-1.png";
import shapeS2 from "../../assets/img/shape/shape-s-2.png";
import useGlobalData from "../../core/hooks/useGlobalData";
import { Link, useNavigate } from "react-router-dom";
import Organization from "../../core/models/organization.model";
import rrrGeneratorService from "../../core/service/rrr-generator.service";
import { HashLink } from "react-router-hash-link";
import ClipLoader from "react-spinners/ClipLoader";
import storage from "../../core/utils/storage";

const Home = () => {
  const { configData } = useGlobalData();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [organizations, setOrganizations] = React.useState<Organization[]>([]);

  React.useEffect(() => {
    getOrganizations();
  }, []);

  const getOrganizations = async () => {
    const data = await rrrGeneratorService.getOrganizations();
    if (data) setOrganizations(data);
    setLoading(false);
  };
  return (
    <>
      <Helmet>
        <title>Flopay | Homepage</title>
        <meta name="description" content="#" />
      </Helmet>

      {!loading ? (
        <main>
          {/* Hero Section */}
          <section className="slider-area fix">
            <div className="shape-slider">
              <img className="shape shape-1 " src={shape1} alt="" />
              <img className="shape shape-2  " src={shape2} alt="" />
              <img className="shape shape-3 " src={shape3} alt="" />
              <img className="shape shape-4 " src={shape4} alt="" />
              <img className="shape shape-5 " src={shape5} alt="" />
              <img className="shape shape-6 " src={shape6} alt="" />
            </div>
            <div className="single-slider slider-height header-bg d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-5 col-lg-6">
                    <div className="slider-text">
                      <h2
                        className="wow fadeInUp animated"
                        data-wow-delay="0.4s"
                      >
                        {/* <span>{configData?.heroTitle}</span> */}
                        <span>Internal Revenue Collection System</span>
                      </h2>
                      <p
                        className="wow fadeInUp animated"
                        data-wow-delay="0.9s"
                      >
                        Pay your tax for greater development
                      </p>
                      <HashLink to="/#org-section">
                        <button
                          type="button"
                          className="x-btn btn-success green-btn wow fadeInUp animated mr-2"
                          data-wow-delay="1.5s"
                        >
                          Generate RRR
                        </button>
                      </HashLink>
                      <HashLink to="/#invoice-section">
                        <button
                          type="button"
                          className="x-btn btn-success  wow fadeInUp animated mt-2 mt-md-0"
                          data-wow-delay="1.5s"
                        >
                          Generate Invoice
                        </button>
                      </HashLink>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 offset-xl-1">
                    <div
                      className="slider-img  mt-50 wow fadeInRight animated"
                      data-wow-duration="2.5s"
                    >
                      <img src={sliderImage} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* How it works */}
          <section className="how-work-area pos-relative pt-150 pb-120">
            <div className="shape-section">
              <img className="shape shape-s-1 " src={shapeS1} alt="" />
              <img className="shape shape-s-2 " src={shapeS2} alt="" />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-xl-6 offset-xl-3">
                  <div className="section-title text-center mb-70">
                    <h2>Features</h2>
                    <p>Start making payments seamlessly</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-4">
                  <div className="how-work mb-30">
                    <div className="how-work-icon">
                      <img src={icon} alt="" />
                    </div>
                    <div className="how-work-text">
                      <h3>Generate RRR</h3>
                      <p>
                        Generate RRR number to make payment to the all revenue
                        service in yobe state
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <div className="how-work mb-30">
                    <div className="how-work-icon">
                      <img src={icon2} alt="" />
                    </div>
                    <div className="how-work-text">
                      <h3>Make Payments</h3>
                      <p>
                        Multiple payment methods available for you to choose
                        your preferred payment option.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <div className="how-work mb-30">
                    <div className="how-work-icon">
                      <img src={icon3} alt="" />
                    </div>
                    <div className="how-work-text">
                      <h3>Check Payment Status</h3>
                      <p>Check your payment status to verify your payment.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* rrr-organizations-section */}
          <div className="hw-area fix mb-50" id="org-section">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-8">
                  <div className="section-title-2 section-title-4 mb-50">
                    <span>Generate RRR</span>
                    <h2>Select MDA to generate RRR</h2>
                  </div>
                </div>
              </div>

              <div className="row">
                {organizations && organizations.length > 0 ? (
                  organizations.map((org, index) => (
                    <React.Fragment key={index.toString()}>
                      {!org.organizationConfig?.supportInvoice &&
                        org.organizationConfig?.showOnWeb && (
                          <div
                            className="col-lg-3 col-md-6"
                            key={index.toString()}
                          >
                            <div className="hw-list text-center mb-30">
                              {org?.organizationConfig?.logoUrl && (
                                <div className="mb-10">
                                  <img
                                    // src={configData?.logoUrl}
                                    src={org?.organizationConfig?.logoUrl || ""}
                                    style={{ width: "50px", height: "auto" }}
                                    alt=""
                                  />
                                </div>
                              )}
                              <h3>{org.name}</h3>
                              <p>{org.organizationType?.name}</p>
                              <div className="mt-10">
                                {!org?.organizationConfig?.websiteUrl ? (
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() => {
                                      storage.set("organizationCode", org.code);

                                      navigate("generate-rrr");
                                    }}
                                  >
                                    Generate RRR
                                  </button>
                                ) : (
                                  <Link
                                    to={`${org?.organizationConfig?.websiteUrl}`}
                                    target="_blank"
                                  >
                                    <button type="button" className="btn">
                                      Generate RRR
                                    </button>
                                  </Link>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                    </React.Fragment>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {/* rrr-organizations-section end */}

          {/* invoice-organizations-section */}
          <div className="hw-area fix" id="invoice-section">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-8">
                  <div className="section-title-2 section-title-4 mb-50">
                    <span>Generate Invoice</span>
                    <h2>Select Organization to generate Invoice</h2>
                  </div>
                </div>
              </div>

              <div className="row">
                {organizations && organizations.length > 0 ? (
                  organizations.map((org, index) => (
                    <React.Fragment key={index.toString()}>
                      {org?.organizationConfig &&
                        org.organizationConfig.supportInvoice &&
                        org.organizationConfig?.showOnWeb && (
                          <div className="col-lg-3 col-md-6">
                            <div className="hw-list text-center mb-30">
                              {org?.organizationConfig?.logoUrl && (
                                <div className="mb-10">
                                  <img
                                    // src={configData?.logoUrl}
                                    src={org?.organizationConfig?.logoUrl || ""}
                                    style={{ width: "50px", height: "auto" }}
                                    alt=""
                                  />
                                </div>
                              )}
                              <h3>{org.name}</h3>
                              <p>{org.organizationType?.name}</p>
                              <div className="mt-10">
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() => {
                                    storage.set("organizationCode", org.code);
                                    navigate("generate-invoice");
                                  }}
                                >
                                  Generate Invoice
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                    </React.Fragment>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {/* invoice-organizations-section end */}
        </main>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ClipLoader
            color={"#ffffff"}
            loading={true}
            cssOverride={
              {
                display: "flex",
                borderColor: "#00c867",
              } as React.CSSProperties
            }
            size={60}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};

export default Home;
