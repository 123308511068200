import ConfigData from "../models/config-data.model";
import apiClient from "./api-client";

const ENDPOINT = "/api/FormalSector";

const genrateInvoice = async (dto: any) => {
  return apiClient.post(`${ENDPOINT}/GenerateInvoice`, dto);
};

const getConfigData = async (orgCode: string): Promise<ConfigData> => {
  const { ok, data }: any = await apiClient.get(
    `${ENDPOINT}/ConfigByOrganization?OrganizationCode=${orgCode}`
  );
  if (ok && data?.data) return new ConfigData(data.data);
  else return new ConfigData(null);
};

const invoiceGeneratorService = {
  genrateInvoice,
  getConfigData,
};

export default invoiceGeneratorService;
