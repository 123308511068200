import React from "react";
import { Controller } from "react-hook-form";
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
} from "primereact/autocomplete";
import PayerQueryParams from "../../../core/models/payer-query-params.model";
import { PaginatedData } from "../../../core/models/paginated-data.model";
import Payer from "../../../core/models/payer.model";
import payerService from "../../../core/service/payer.service";
import { SelectItem } from "../../../core/interfaces/select-item.interface";

interface SearchPayerProps {
  name: string;
  control: any;
  rules: any;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  setValue: any;
}

export default function SearchPayer({
  name,
  control,
  rules,
  defaultValue,
  placeholder,
  label,
  disabled,
  setValue,
}: SearchPayerProps) {
  const [payers, setPayers] = React.useState<Payer[]>([]);
  const [payerSelectItems, setPayerSelectItems] = React.useState<SelectItem[]>(
    []
  );
  const [searchParams, setSearchParams] = React.useState<PayerQueryParams>(
    new PayerQueryParams()
  );

  const searchPayerByBusinessName = async (
    event: AutoCompleteCompleteEvent
  ) => {
    searchParams.businessName = event.query;
    searchPayers(searchParams);
  };

  const searchPayers = async (query: PayerQueryParams) => {
    const { data, totalItems, problem }: PaginatedData<Payer[]> =
      await payerService.search(query);

    console.log("data :>> ", data);
    if (data && data.length > 0) {
      setPayers(data);
      const billersSelectItemsData: any = data.map((x: Payer) => {
        return { label: x.businessName, value: x.payerId };
      });

      setPayerSelectItems(billersSelectItemsData);
    }
    // setSearchParams(query);
  };

  return (
    <div className="w-full mb-2">
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({
          field: { value, onChange, ref },
          fieldState: { error },
        }: any) => (
          <div className="country-select">
            <label>
              {label} <span className="required">*</span>
            </label>

            <AutoComplete
              inputId={name}
              field="label"
              value={value}
              onChange={onChange}
              inputRef={ref}
              className="w-full"
              placeholder={placeholder}
              suggestions={payerSelectItems}
              itemTemplate={(suggestion, index) => (
                <div key={index.toString()}>{suggestion.label}</div>
              )}
              completeMethod={searchPayerByBusinessName}
              style={{ width: "100%" }}
              // panelFooterTemplate={panelFooterTemplate}
            />

            <small className="p-error text-[10px] font-sans">
              {error?.message}
            </small>
          </div>
        )}
      />
    </div>
  );
}
