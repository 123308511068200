export default class InvoiceData {
  formalSectorCollectionId!: number;
  invoiceFileUrl!: string;

  constructor(dto: any = null) {
    if (dto) {
      this.formalSectorCollectionId = dto.formalSectorCollectionId;
      this.invoiceFileUrl = dto.invoiceFileUrl;
    }
  }
}
