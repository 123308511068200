export class PaginatedData<TData> {
  data: TData;
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  problem?: string;
  extras?: any;

  constructor(
    data: any = null,
    pageNumber: number = 1,
    pageSize: number = 4,
    totalItems: number = 0,
    problem: string = ""
  ) {
    this.data = data;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.totalItems = totalItems;
    this.problem = problem;
  }
}
