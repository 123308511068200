export default class QueryParams {
  pageNumber: number;
  pageSize: number;
  orderBy!: string;
  orderDirection: string;

  constructor({ PageNumber, PageSize, OrderBy, OrderDirection }: any = {}) {
    this.pageNumber = PageNumber || 1;
    this.pageSize = PageSize || 50;
    this.orderBy = OrderBy || "";
    this.orderDirection = OrderDirection || "";
  }
}
