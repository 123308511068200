export default class Payer {
  payerId!: number;
  firstName!: string;
  lastName!: string;
  fullName!: string;
  license!: string;
  phoneNo!: string;
  registrationNumber!: string;
  taxIdentificationNumber!: string;
  businessAddress!: string;
  payerCode!: string;
  stateId!: number;
  cityId!: number;
  industryId!: number;
  industrySectorId!: number;
  statusId!: number;
  cityNumber!: number;
  stateName!: string;
  cityName!: string;
  industryName!: string;
  industrySectorName!: string;
  businessName!: string;
  businessDescription!: string;
  businessOwner!: string;
  businessLGA!: string;
  emailAddress!: string;
  organizationPayerCategoryId!: number;
  organizationPayerCategoryName!: string;
  nin!: string;
  bvn!: string;
  addressCode!: string;
  tax!: string[];

  constructor(data: any = null) {
    if (data) {
      this.payerId = data.payerId;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.fullName =
        data.firstName && data.lastName
          ? data.firstName + " " + data.lastName
          : "";
      this.license = data.license;
      this.phoneNo = data.phoneNo;
      this.registrationNumber = data.registrationNumber;
      this.taxIdentificationNumber = data.taxIdentificationNumber;
      this.businessAddress = data.businessAddress;
      this.payerCode = data.payerCode;
      this.stateId = data.stateId;
      this.cityId = data.cityId;
      this.industryId = data.industryId;
      this.industrySectorId = data.industrySectorId;
      this.statusId = data.statusId;
      this.cityNumber = data.cityNumber;
      this.cityName = data.cityName;
      this.stateName = data.stateName;

      this.industryName = data.industryName;
      this.industrySectorName = data.industrySectorName;
      this.businessName = data.businessName;
      this.businessDescription = data.businessDescription;
      this.businessOwner = data.businessOwner;
      this.businessLGA = data.businessLGA;
      this.emailAddress = data.emailAddress;
      this.organizationPayerCategoryId = data.organizationPayerCategoryId;
      this.organizationPayerCategoryName = data.organizationPayerCategoryName;
      this.bvn = data.bvn;
      this.nin = data.nin;
      this.addressCode = data.addressCode;
      this.tax = data.tax;
    }
  }
}
