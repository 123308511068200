import { PaginatedData } from "../models/paginated-data.model";
import PayerQueryParams from "../models/payer-query-params.model";
import Payer from "../models/payer.model";
import apiClient from "./api-client";

const ENDPOINT = "/api/Payer";

const search = async (
  searchParam: PayerQueryParams
): Promise<PaginatedData<Payer[]>> => {
  let payers: PaginatedData<Payer[]> = new PaginatedData<Payer[]>();

  const { ok, data }: any = await apiClient.post(
    `${ENDPOINT}/Search`,
    searchParam
  );

  if (ok && data) {
    payers.pageNumber = data?.data.pageNumber;
    payers.pageSize = data?.data.pageSize;
    payers.totalItems = data?.data.totalItems;
    payers.data = data?.data.items.map((payer: any) => new Payer(payer));

    return payers;
  }

  return payers;
};

const payerService = {
  search,
};

export default payerService;
