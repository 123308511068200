import QueryParams from "./query-params.model";

export default class PayerQueryParams extends QueryParams {
  payerCode!: string;
  organizationId!: number;
  stateId!: number;
  statusId!: number;
  cityId!: number;
  industryId!: number;
  industrySectorId!: number;
  registrationNumber!: string;
  payerName!: string;
  addressCode!: string;
  businessName!: string;

  constructor({
    PageNumber,
    PageSize,
    OrderBy,
    OrderDirection,
    applyPaging,
  }: any = {}) {
    super({ PageNumber, PageSize, OrderBy, OrderDirection, applyPaging });

    this.payerCode = "";
    this.organizationId = 0;
    this.stateId = 0;
    this.cityId = 0;
    this.industryId = 0;
    this.industrySectorId = 0;
    this.payerName = "";
    this.statusId = 0;
    this.registrationNumber = "";
    this.addressCode = "";
    this.businessName = "";
  }
}
