export default class ConfigData {
  splitRrrConfigId!: number;
  organizationId!: number;
  logoUrl!: string;
  brandName!: string;
  heroTitle!: string;
  organizationName!: string;
  organizationCode!: string;
  organizationFscConfigId!: number;
  websiteUrl!: string;
  supportInvoice!: boolean;
  invoiceTemplateCode!: string;
  showOnWeb!: boolean;

  constructor(dto: any = null) {
    if (dto) {
      this.splitRrrConfigId = dto.splitRrrConfigId;
      this.organizationId = dto.organizationId;
      this.logoUrl = dto.logoUrl;
      this.brandName = dto.brandName;
      this.heroTitle = dto.heroTitle;
      this.organizationName = dto.organizationName;
      this.organizationCode = dto.organizationCode;
      this.organizationFscConfigId = dto.organizationFscConfigId;
      this.websiteUrl = dto.websiteUrl;
      this.supportInvoice = dto.supportInvoice;
      this.invoiceTemplateCode = dto.invoiceTemplateCode;
      this.showOnWeb = dto.showOnWeb;
    }
  }
}
