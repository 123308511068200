import ConfigData from "./config-data.model";

export default class Organization {
  organizationId!: number;
  organizationType!: { name: string, code: string };
  name!: string;
  registrationNo!: string;
  statusId!: number;
  statusName!: string;
  email!: string;
  phoneNo!: string;
  code!: string;
  organizationConfig!: ConfigData

  constructor(dto: any = null) {
    if (dto) {
      this.organizationId = dto.organizationId;
      this.organizationType = dto.organizationType;
      this.name = dto.name;
      this.registrationNo = dto.registrationNo;
      this.statusId = dto.statusId;
      this.statusName = dto.statusName;
      this.email = dto.email;
      this.phoneNo = dto.phoneNo;
      this.code = dto.code;
      this.organizationConfig = dto.organizationConfig && new ConfigData(dto.organizationConfig);
    }
  }
}
