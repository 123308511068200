import BillerProduct from "../models/biller-product.model";
import Biller from "../models/biller.model";
import ConfigData from "../models/config-data.model";
import Organization from "../models/organization.model";
import TransactionStatus from "../models/transaction-status.model";
import apiClient from "./api-client";

const ENDPOINT = "/api/FormalSector";

const getRemitaBillers = async (orgCode: string): Promise<Biller[]> => {
  const { ok, data }: any = await apiClient.get(
    `${ENDPOINT}/RemitaBillers?OrganizationCode=${orgCode}`
  );
  let items: Biller[] = [];
  if (ok && data) {
    items =
      data.data.data && data.data.data.length > 0
        ? data.data.data.map((x: any) => new Biller(x))
        : [];
  }
  return items;
};

const getBillerProducts = async (id: string): Promise<BillerProduct> => {
  const { ok, data, status }: any = await apiClient.get(
    `${ENDPOINT}/RemitaBillerProducts?remitaBillerId=${id}`
  );

  if (ok && data?.data && status === 200)
    return new BillerProduct(data.data?.data);
  else return new BillerProduct(null);
};

const createCollection = async (dto: any) => {
  return apiClient.post(`${ENDPOINT}/CreateCollection`, dto);
};

const getConfigData = async (orgCode: string): Promise<ConfigData> => {
  const { ok, data }: any = await apiClient.get(
    `${ENDPOINT}/ConfigByOrganization?OrganizationCode=${orgCode}`
  );
  if (ok && data?.data) return new ConfigData(data.data);
  else return new ConfigData(null);
};

const checkTransactionStatus = async (rrr: string) => {
  return await apiClient.get(`${ENDPOINT}/CheckTransactionStatus?Rrr=${rrr}`);
};

const getOrganizations = async (): Promise<Organization[]> => {
  let items: Organization[] = [];
  const { ok, data }: any = await apiClient.get(
    `${ENDPOINT}/GetAllFormalSectorOrganizations`
  );
  console.log("data :>> ", data);
  if (ok && data.isSuccess) {
    items = data?.data?.map((x: any) => new Organization(x));
  }
  return items;
};

const rrrGeneratorService = {
  getRemitaBillers,
  getBillerProducts,
  createCollection,
  getConfigData,
  checkTransactionStatus,
  getOrganizations,
};

export default rrrGeneratorService;
